import z from "zod";
import { addYears } from "date-fns";
import { AvailabilityParams } from "src/hooks/useMews";
import { ConfigurationResult, RestrictionsResult, ServiceAvailabilityResult } from "./mews.schema";

export type ReservationParams = {
  rateId: string;
  roomCategoryId: string;
  occupancy: Record<string, number>;
  bookingDate: {
    startDate: string;
    endDate: string;
  };
  notes: string;
  serviceId: string;
  customerId: string;
};

const fetchMews = <T extends z.ZodTypeAny>(url: string, options: RequestInit, schema: T) =>
  fetch(`${process.env.NEXT_PUBLIC_MEWS_BASE_URL}${url}`, {
    ...options,
    method: "POST",
    headers: {
      ...options.headers,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...JSON.parse(options.body as string),
      Client: process.env.NEXT_PUBLIC_MEWS_CLIENT_ID,
    }),
  })
    .then((res) => {
      if (!res.ok) {
        return res.text().then((text) => {
          throw new Error(res.statusText, {
            cause: text,
          });
        });
      }
      return res.json();
    })
    .then<z.infer<T>>(schema.parse);

const configurationRequest = () =>
  fetchMews(
    "/api/distributor/v1/configuration/get",
    {
      body: JSON.stringify({
        Ids: process.env.NEXT_PUBLIC_MEWS_BOOKING_ENGINE_ID.split(","),
      }),
    },
    ConfigurationResult,
  );

const serviceAvailabilityRequest = ({ enterpriseId, serviceId, categoryId, startDate }: AvailabilityParams) => {
  const start = startDate ?? new Date();
  start.setDate(1);

  const end = addYears(start, 1);

  return fetchMews(
    "/api/distributor/v1/services/getAvailability",
    {
      body: JSON.stringify({
        EnterpriseId: enterpriseId,
        ServiceId: serviceId,
        StartUtc: start.toISOString(),
        EndUtc: end.toISOString(),
        CategoryIds: [categoryId],
      }),
    },
    ServiceAvailabilityResult,
  );
};

const restrictionsRequest = (serviceId: string, categoryId) =>
  fetchMews(
    "/api/connector/v1/restrictions/getAll",
    {
      body: JSON.stringify({
        ClientToken: process.env.MEWS_CLIENT_TOKEN,
        AccessToken: process.env.MEWS_ACCESS_TOKEN,
        ServiceIds: [serviceId],
        ResourceCategoryIds: [categoryId],
      }),
    },
    RestrictionsResult,
  );

export { configurationRequest, serviceAvailabilityRequest, restrictionsRequest };
