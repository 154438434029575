import { format } from "date-fns";
import z from "zod";

const OccupancyData = z.object({ AgeCategoryId: z.string().uuid(), PersonCount: z.number().int() });

const BookingEngineRequiredFields = z.literal("Telephone");

const PricingMode = z.enum(["Gross", "Net"]);

const TranslationRecord = z.record(z.string()).transform((translation) => translation["fr-FR"]);

const Category = z.object({
  Id: z.string().uuid(),
  Name: TranslationRecord,
  Description: TranslationRecord,
  Ordering: z.number().int(),
  NormalBedCount: z.number().int(),
  ExtraBedCount: z.number().int(),
});

const Rate = z.object({
  Id: z.string().uuid(),
  RateGroupId: z.string().uuid(),
  Ordering: z.number().int(),
  Name: TranslationRecord.nullish(),
  Description: TranslationRecord.nullish(),
  IsPrivate: z.boolean(),
  CurrencyCode: z.string().min(1).max(3),
});

const ResourceCategoryImageAssignment = z.object({
  Id: z.string().uuid(),
  CategoryId: z.string().uuid(),
  ImageId: z.string().uuid(),
  Ordering: z.number().int(),
});

const AmountPart = z.enum(["NetValue", "TaxValue", "GrossValue"]);

const RelativeProductPrice = z.object({
  ProductIds: z.array(z.string()),
  TaxRateCodes: z.array(z.string()),
  Multiplier: z.number(),
  Target: AmountPart,
});

const ProductChargingMode = z.enum(["Once", "PerTimeUnit", "PerPersonPerTimeUnit", "PerPerson"]);

const ProductPostingMode = z.enum(["Once", "PerTimeUnit"]);

const Product = z.object({
  Id: z.string().uuid(),
  Name: TranslationRecord,
  Description: TranslationRecord,
  CategoryId: z.string().uuid().nullish(),
  ImageId: z.string().uuid().nullish(),
  IncludedByDefault: z.boolean(),
  AlwaysIncluded: z.boolean(),
  Prices: z.unknown(),
  Pricing: z.object({ discriminator: z.string(), value: RelativeProductPrice }).partial().passthrough(),
  Amounts: z.unknown(),
  RelativePrice: z.number().nullish(),
  ChargingMode: ProductChargingMode,
  PostingMode: ProductPostingMode,
  Ordering: z.number().int(),
});

const Enterprise = z.object({
  Id: z.string().uuid(),
  Name: TranslationRecord,
  Description: TranslationRecord,
  ImageId: z.string().uuid().nullish(),
  IntroImageId: z.string().uuid().nullish(),
  Email: z.string().min(1),
  Telephone: z.string().nullish(),
  TermsAndConditionsUrl: z.record(z.string()).nullish(),
  PrivacyPolicyUrl: z.record(z.string()).nullish(),
  Pricing: PricingMode,
  Categories: z.array(Category),
  Rates: z.array(Rate),
  CategoryImageAssignments: z.array(ResourceCategoryImageAssignment),
  Products: z.array(Product),
});

const AgeCategoryClassification = z.enum(["Adult", "Child"]);
const AgeCategory = z.object({
  Id: z.string().uuid(),
  ServiceId: z.string().uuid(),
  Name: TranslationRecord,
  ShortName: TranslationRecord,
  MinimalAge: z.number().int().nullish(),
  MaximalAge: z.number().int().nullish(),
  Classification: AgeCategoryClassification,
  IsDefault: z.boolean(),
});

const BookingEngine = z.object({
  Id: z.string().uuid(),
  ServiceId: z.string().uuid(),
  AdultCount: z.number().int().nullish(),
  ChildCount: z.number().int().nullish(),
  MaxAdultCount: z.number().int().nullish(),
  OccupancyData: z.array(OccupancyData).nullish(),
  DisplayAvailability: z.boolean().nullish(),
  RequiredFields: z.array(BookingEngineRequiredFields),
  OnlineTravelAgencies: z.array(z.string()).nullish(),
  CheckoutStatement: z.record(z.string()),
  CompetitorRateDescription: z.record(z.string()),
  CompetitorPriceRelativeAdjustment: z.number().nullish(),
  Enterprise: Enterprise,
});

const TimeUnitPeriod = z.enum(["Day", "Month", "Hour"]);
const BookableService = z.object({
  StartOffset: z.string().min(1),
  EndOffset: z.string().min(1),
  TimeUnit: TimeUnitPeriod,
});

const Service = z.object({
  Id: z.string().uuid(),
  EnterpriseId: z.string().uuid(),
  Ordering: z.number().int(),
  CreatedUtc: z.string().min(1).datetime({ offset: true }),
  Names: TranslationRecord,
  ShortNames: z.record(z.string()),
  IsActive: z.boolean(),
  Data: z.object({ discriminator: z.string(), Value: BookableService }).partial().passthrough(),
});

const ConfigurationResult = z.object({
  ImageBaseUrl: z.string().min(1).url(),
  Configurations: z.array(BookingEngine),
  AgeCategories: z.array(AgeCategory),
  Services: z.array(Service),
});

const CategoryAvailability = z.object({ CategoryId: z.string().uuid(), Availabilities: z.array(z.number()) });

const RestrictionType = z.enum(["ClosedToStay", "ClosedToArrival", "ClosedToDeparture", "Start", "End", "Stay"]);

const ResourceCategoryType = z.enum([
  "Room",
  "Bed",
  "Dorm",
  "Apartment",
  "Suite",
  "Villa",
  "Site",
  "Office",
  "MeetingRoom",
  "ParkingSpot",
  "Desk",
  "TeamArea",
  "Membership",
  "Tent",
  "CaravanOrRV",
  "UnequippedCampsite",
  "Bike",
]);

const RestrictionConditions = z.object({
  Type: RestrictionType,
  ExactRateId: z.string().uuid().nullish(),
  BaseRateId: z.string().uuid().nullish(),
  RateGroupId: z.string().uuid().nullish(),
  ResourceCategoryId: z.string().uuid().nullish(),
  ResourceCategoryType: ResourceCategoryType.nullish(),
  StartUtc: z.string().datetime({ offset: true }).nullish(),
  EndUtc: z.string().datetime({ offset: true }).nullish(),
  Days: z.array(z.string()),
});

const RestrictionExceptions = z
  .object({
    MinAdvance: z.string().nullable(),
    MaxAdvance: z.string().nullable(),
    MinLength: z.string().nullable(),
    MaxLength: z.string().nullable(),
    MinPrice: z.number().nullable(),
    MaxPrice: z.number().nullable(),
    PriceCurrencyCode: z.string().max(3).nullable(),
    MaxReservationCount: z.number().int().nullable(),
  })
  .partial();

const Restriction = z.object({
  Id: z.string().uuid(),
  ServiceId: z.string().uuid(),
  Conditions: RestrictionConditions,
  Exceptions: RestrictionExceptions,
  ExternalIdentifier: z.string().nullish(),
});

const ServiceAvailabilityResult = z
  .object({
    TimeUnitStartsUtc: z.array(z.string()),
    CategoryAvailabilities: z.array(CategoryAvailability),
    ApplicableRestrictions: z.array(Restriction),
  })
  .transform((result) => {
    const availabilities = result.TimeUnitStartsUtc.reduce<Record<string, number>>(
      (acc, timeUnitStart, index) => ({
        ...acc,
        [timeUnitStart]: result.CategoryAvailabilities[0].Availabilities[index],
      }),
      {},
    );
    return { ...result, Availabilities: availabilities };
  });

const TaxValue = z.object({ TaxRateCode: z.string().min(1), Value: z.number() });
const TaxBreakdownItem = z.object({ TaxRateCode: z.string().nullish(), NetValue: z.number(), TaxValue: z.number() });
const TaxBreakdown = z.object({ Items: z.array(TaxBreakdownItem) });

const Amount = z.object({
  Currency: z.string().min(1).max(3),
  GrossValue: z.number(),
  NetValue: z.number(),
  TaxValues: z.array(TaxValue),
  Breakdown: TaxBreakdown,
});

const ProductOptions = z.object({
  SelectedByDefault: z.boolean(),
  BillAsPackage: z.boolean(),
  OfferToCustomer: z.boolean(),
  ExcludePriceFromOffer: z.boolean(),
  OfferToEmployee: z.boolean(),
});

const ProductOrderPricingInfo = z.object({
  ProductId: z.string().uuid(),
  AgeCategoryId: z.string().uuid().nullish(),
  ProductName: z.record(z.string()),
  ProductOptions: ProductOptions,
  ChargingMode: ProductChargingMode,
  TotalAmount: Amount,
});

const ReservationPricingInfo = z.object({
  Identifier: z.string().nullish(),
  TotalAmount: Amount,
  ProductOrderPrices: z.array(ProductOrderPricingInfo),
});

const ReservationPriceResult = z.object({ ReservationPrice: z.array(ReservationPricingInfo) });

const Reservation = z.object({
  Id: z.string().uuid(),
  Identifier: z.string().nullish(),
  Number: z.string().min(1),
  StartUtc: z.string().min(1).datetime({ offset: true }),
  EndUtc: z.string().min(1).datetime({ offset: true }),
  VoucherCode: z.string().nullish(),
  AvailabilityBlockId: z.string().uuid().nullish(),
  RoomCategoryId: z.string().uuid(),
  RateId: z.string().uuid(),
  Rate: Rate.optional(),
  ProductIds: z.array(z.string()).nullish(),
  AdultCount: z.number().int().nullish(),
  ChildCount: z.number().int().nullish(),
  OccupancyData: z.array(OccupancyData),
  Notes: z.string().nullish(),
  Cost: z.unknown(),
  Amount: z.unknown(),
});

const PaymentRequestState = z.enum(["Pending", "Completed", "Canceled", "Expired"]);

const PaymentRequest = z.object({
  Id: z.string().uuid(),
  ReservationGroupId: z.string().uuid(),
  State: PaymentRequestState,
});
const PaymentState = z.enum(["Charged", "Canceled", "Pending", "Failed", "Verifying"]);

const Payment = z.object({
  Id: z.string().uuid(),
  EnterpriseId: z.string().uuid(),
  PaymentRequestId: z.string().uuid().nullish(),
  CreatedUtc: z.string().min(1).datetime({ offset: true }),
  State: PaymentState,
  Amount: Amount,
  ChargeAmount: Amount.optional(),
});

const ReservationGroup = z.object({
  Id: z.string().uuid(),
  CustomerId: z.string().uuid().nullish(),
  Reservations: z.array(Reservation),
  PaymentRequestId: z.string().uuid().nullish(),
  TotalCost: z.unknown(),
  TotalAmount: z.unknown(),
  PaymentCardId: z.string().uuid().nullish(),
  CreditCardAvailable: z.boolean(),
  PaymentRequests: z.array(PaymentRequest).nullish(),
  Payments: z.array(Payment).nullish(),
});

const RestrictionsResult = z.object({
  Restrictions: z.array(Restriction),
});

const ServicePricingRequest = z.object({
  CategoryPrices: z.array(
    z.object({
      CategoryId: z.string().uuid(),
      OccupancyPrices: z.array(
        z.object({
          RateGroupPrices: z.array(
            z.object({
              MinRateId: z.string().uuid(),
              MinPrice: z.object({
                TotalAmount: Amount,
              }),
            }),
          ),
        }),
      ),
    }),
  ),
});

export {
  ConfigurationResult,
  Category,
  ServiceAvailabilityResult,
  ReservationPriceResult,
  ReservationGroup,
  RestrictionsResult,
  ServicePricingRequest,
};
