import { format } from "date-fns";
import { ServiceAvailabilityResult } from "./mews.schema";
import { z } from "zod";

/**
 * Formats availability data from Mews API to ensure consistent date formatting
 *
 * This function takes raw availability data from the Mews API and reformats the dates
 * to ensure they are consistently in YYYY-MM-DD format. This is necessary because:
 *
 * 1. The Mews API returns dates in UTC format with timezone information
 * 2. We need dates in YYYY-MM-DD local format for consistent comparison and display
 * 3. The formatting helps prevent timezone-related issues when working with dates
 *
 */
export function formatAvailabilities(availabilities: z.infer<typeof ServiceAvailabilityResult>["Availabilities"]) {
  return Object.entries(availabilities).reduce(
    (acc, [date, count]) => ({
      ...acc,
      [format(new Date(date), "yyyy-MM-dd")]: count,
    }),
    {},
  );
}
